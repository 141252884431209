// src/redux/slices/ssldataSlice.js
import { createSlice } from '@reduxjs/toolkit';

const ssldataSlice = createSlice({
  name: 'ssldata',
  initialState: {
    data: [],
    loading: false,
    error: null,
    // currentPage: 1,
    // pageSize: 10,
    totalItems: 0,
  },
  reducers: {
    fetchDataStart(state) {
      state.loading = true;
    },
    fetchDataSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data;
      state.totalItems = action.payload.totalItems;
    },
    fetchDataFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    // setPage(state, action) {
    //   state.currentPage = action.payload;
    // },
    // setPageSize(state, action) {
    //   state.pageSize = action.payload;
    // },
  },
});

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure, setPage, setPageSize } = ssldataSlice.actions;
export default ssldataSlice.reducer;
