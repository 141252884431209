import {styled} from "@mui/material";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import formatDate from "../../../service/formatdate";
// import formatTime from "../../../service/fromatedtime";
import { tokens } from "../../../theme";
import {useTheme} from "@mui/material";

const OverlayBoxBottom = styled(Box)(({ theme }) => ({
    // position: 'absolute',
    bottom: 0,
    // left:'50px',
    top:'350px',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    // Ensure overflow is applied to this container
    overflow: 'visible', // or 'auto' or 'scroll' as needed
  }));
// import OverlayBoxBottom from './OverlayBoxBottom'; // Adjust the import path accordingly

const AlertSection = ({ deviceId }) => {         

  console.log("the device id in Alertsection",deviceId)
  const [data, setData] = useState([]);

  useEffect(() => {
    if (deviceId) {
      axios.post('http://localhost:4000/api/v1/getAlertbyid', 
        {
          deviceid: deviceId
        }
      ).then((response) => {
        console.log("The response data is", response);
        setData(response.data.faultdata); // Assuming the data comes in response.data.data
      }).catch((err) => {
        console.log("The error is", err.message);
      });
    }
  }, [deviceId]);

  const columns = [
    { field: 'luminaireOnTime', headerName: 'Luminaire On Time', width: 150 },
    { field: 'luminaireOffTime', headerName: 'Luminaire Off Time', width: 150 },
    { field: 'batteryStateOfCharge', headerName: 'Battery State of Charge (%)', width: 200 },
    { field: 'batteryDOD', headerName: 'Battery DOD', width: 150 },
    { field: 'sNo', headerName: 'S.No', width: 90 }, // Ensure field names match response data
    { field: 'time', headerName: 'Time', width: 150 },
    { field: 'date', headerName: 'Date', width: 200, type: 'date' }
  ];
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Transform data to match the expected structure for DataGrid rows
  const rows = data.map((item, index) => ({
    id: item._id,
    sNo: item.sNo,
    luminaireOnTime: (item.luminaireOnTime),
    luminaireOffTime: formatDate(item.luminaireOffTime),
    batteryStateOfCharge: item.batteryStateOfCharge,
    batteryDOD: item.batteryDOD,
    time: (item.time),
    date:formatDate(item.date), // Format date if needed
  }));

  return (
    <OverlayBoxBottom theme={theme}  colors = { colors}  >
      <Box display="flex" flexDirection="column" gap={2}>
        <Box sx={{ height: 400, width: '100%' , backgroundColor: theme.palette.mode === 'dark'? colors.primary[500] : 'white', }}>
          <DataGrid
            initialState={{
              pagination: { paginationModel: { pageSize: 15, page: 0 } },
            }}
            pageSizeOptions={[5, 10, 25]}
            columns={columns}
            rows={rows}
            disableSelectionOnClick
            autoPageSize
          />
        </Box>
      </Box>
    </OverlayBoxBottom>
  );
};

export default AlertSection;
