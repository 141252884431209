// import { Box  } from "@mui/material";
// import { styled } from '@mui/system';
// import { DataGrid } from "@mui/x-data-grid";
// import axios from "axios";
// import { useEffect, useState } from "react";

// import React, { useState } from 'react';


// const  FaultSection = ({ deviceId }) =>{

//     const [faultdata , setFaultdata] = useState([]);
//     let data = [];
//     for (let i = 1; i <= 50; i++) {
//       data.push({
//         id: i,
//         serialNumber: i,
//         date: `2024-06-${i.toString().padStart(2, '0')}`,
//         time: `12:00 PM`,
//         batteryFault: `Battery Fault ${i}`,
//         moduleFault: `Module Fault ${i}`,
//         luminaryFault: `Luminary Fault ${i}`,
//         faultRectificationDate: `2024-07-${i.toString().padStart(2, '0')}`,
//       });
//     }
  
//     const columns = [
//       { field: 'serialNumber', headerName: 'S.No', width: 90 },
//       { field: 'date', headerName: 'Date', width: 150 },
//       { field: 'time', headerName: 'Time', width: 150 },
//       { field: 'batteryFault', headerName: 'Battery Fault', width: 150 },
//       { field: 'moduleFault', headerName: 'Module Fault', width: 150 },
//       { field: 'luminaryFault', headerName: 'Luminary Fault', width: 150 },
//       { field: 'faultRectificationDate', headerName: 'Fault Rectification Date', width: 200 },
//     ];
  
//     // S.No	Date	Time	Luminaire On Time	Luminaire Off Time	Battery Sate of charge (%)	Battery DOD(%)

//     useEffect( ()=>{
//         axios.get('http://localhost:4000/api/v1/getfaults',{
//           params: { deviceid: deviceId }, // Pass `deviceid` as a query parameter
//         }).then(
//           (response)=>{

//             console.log( " This is the conosole of the data ", response );
//             setFaultdata(response.data.FaultSectiondata);
//           }
//         ).catch((error)=>{
//           console.log("Error while creating geting the faultdata ")
//         })
//     },[])

  
//     return(
//       <OverlayBoxBottom>
//       <Box display="flex" flexDirection="column" gap={2}>
//         <Box>
//         <Box sx={{ height: 400, width: '100%' }}>
//           <DataGrid
//            initialState={{
//             // ...data.initialState,
//             pagination: { paginationModel: { pageSize: 15, page: 0 }, },   
//           }}
//           pageSizeOptions={[5, 10, 25]}
//             rows={data}
//             columns={columns}
//             disableSelectionOnClick
//             autoPageSize
//           />
//         </Box>
//         </Box>
//       </Box>
//     </OverlayBoxBottom>
//     )
//   }

//   export default FaultSection

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
// import OverlayBoxBottom from './OverlayBoxBottom'; // Assuming you have this component
import { tokens } from "../../../theme";
import {useTheme} from "@mui/material";

const OverlayBoxBottom = styled(Box)(({ theme }) => ({
  // position: 'absolute',
  bottom: 0,
  // left:'50px',
  top:'350px',
  width: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  // Ensure overflow is applied to this container
  overflow: 'visible', // or 'auto' or 'scroll' as needed
}));


const FaultSection = ({ deviceId }) => {
  const [faultdata, setFaultdata] = useState([]);

  useEffect(() => {
    axios.get('http://localhost:4000/api/v1/getfaults', {
      params: { deviceid: deviceId },
    })
    .then((response) => {
      console.log(" This is the console of the data ", (response.data.FaultSectiondata.Faults));
      setFaultdata(response.data.FaultSectiondata.Faults);
    })
    .catch((error) => {
      console.log("Error while getting the fault data", error);
    });
  }, [deviceId]);

  const rows = faultdata.map((item, index) => ({
    id: item._id,
    serialNumber: index + 1,
    date: new Date(item.date).toLocaleDateString(),
    time: item.time,
    batteryFault: item.batteryFault,
    moduleFault: item.moduleFault,
    luminaryFault: item.luminaryFault,
    faultRectificationDate: new Date(item.faultRectificationDate).toLocaleDateString(),
  }));

  const columns = [
    { field: 'serialNumber', headerName: 'S.No', width: 90 },
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'time', headerName: 'Time', width: 150 },
    { field: 'batteryFault', headerName: 'Battery Fault', width: 150 },
    { field: 'moduleFault', headerName: 'Module Fault', width: 150 },
    { field: 'luminaryFault', headerName: 'Luminary Fault', width: 150 },
    { field: 'faultRectificationDate', headerName: 'Fault Rectification Date', width: 200 },
  ];

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <OverlayBoxBottom>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box sx={{ height: 400, width: '100%' , backgroundColor: theme.palette.mode === 'dark'? colors.primary[500] : 'white' }}>
          <DataGrid
            initialState={{
              pagination: { paginationModel: { pageSize: 15, page: 0 } },
            }}
            pageSizeOptions={[5, 10, 25]}
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            autoPageSize
          />
        </Box>
      </Box>
    </OverlayBoxBottom>
  );
};

export default FaultSection;
