import React, { useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { GoogleMap, InfoWindow, Marker, useLoadScript } from '@react-google-maps/api';
import {  useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const libraries = ['places'];

const mapContainerStyle = {
  width: '100%',
  height: '80vh',
};

const center = {
  lat: 18.5204,
  lng: 73.8567,
};

const markers = [
  {
    lat: 18.5204,
    lng: 73.8567,
    lastLog: new Date().toString(),
  },
];

const Geolocation = () => {
  // const dispatch = useDispatch();
  const urlpath = useLocation();
  const deviceId = urlpath.pathname.split('/').filter(Boolean).pop();
  console.log("This is the path of the GIS location:", deviceId);

  const ssldata = useSelector((state) => state.ssldata.data);
  console.log(ssldata);

  const info = ssldata.find(item => item.Deviceid === deviceId);
  console.log("The device id info:", info);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBlxfvUDeq03ZdbRXt-HfRoObG8CdN7aOE',
    libraries,
  });

  const [mapRef, setMapRef] = useState(null);
  const [infoWindowData, setInfoWindowData] = useState(null);

  const onMapLoad = (map) => {
    setMapRef(map);
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach(({ lat, lng }) => bounds.extend(new window.google.maps.LatLng(lat, lng)));
    map.fitBounds(bounds);
  };

  const handleMarkerClick = (markerData) => {
    if (mapRef) {
      mapRef.panTo({ lat: markerData.lat, lng: markerData.lng });
      setInfoWindowData(info ? { ...info, lat: markerData.lat, lng: markerData.lng } : null);
    }
  };

  if (loadError) {
    return <Box>Error loading maps</Box>;
  }

  if (!isLoaded) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant='h3'>GIS LOCATION</Typography>
        <Typography variant='h6'>Dashboard - GIS LOCATION</Typography>
      </Box>
      <Box sx={{ maxWidth: '90vw', height: '80vh', boxShadow: 3, borderRadius: 2, overflow: 'hidden' }}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={10}
          center={center}
          onLoad={onMapLoad}
          onClick={() => setInfoWindowData(null)} // Close InfoWindow on map click
        >
          {markers.map((markerData, index) => (
            <Marker
              key={index}
              position={{ lat: markerData.lat, lng: markerData.lng }}
              onClick={() => handleMarkerClick(markerData)}
              icon={{
                url: 'https://solarstreetlightyojana.com/uploads/048913c8-58d1-4c96-98b7-5ea8c0d0b42f.png',
                scaledSize: new window.google.maps.Size(40, 40),
                anchor: new window.google.maps.Point(15, 15),
              }}
            />
          ))}
          {infoWindowData && (
            <InfoWindow
              position={{ lat: infoWindowData.lat, lng: infoWindowData.lng }}
              onCloseClick={() => setInfoWindowData(null)}
            >
              <Box p={1} sx={{ color: 'black' }}>
                <Typography variant="subtitle1"><strong>Device ID:</strong> {infoWindowData.Deviceid || 'N/A'}</Typography>
                <Typography variant="subtitle2"><strong>Pole S.No.:</strong> {infoWindowData.Pole_sr_no || 'N/A'}</Typography>
                <Typography variant="subtitle2"><strong>Luminary S.No.:</strong> {infoWindowData.Luminiare_sr_no || 'N/A'}</Typography>
                <Typography variant="subtitle2"><strong>Battery S.No.:</strong> {infoWindowData.Battery_sr_no || 'N/A'}</Typography>
                <Typography variant="subtitle2"><strong>District:</strong> {infoWindowData.district || 'N/A'}</Typography>
                <Typography variant="subtitle2"><strong>Block:</strong> {infoWindowData.block || 'N/A'}</Typography>
                <Typography variant="subtitle2"><strong>Panchayat:</strong> {infoWindowData.panchayat || 'N/A'}</Typography>
                <Typography variant="subtitle2"><strong>Ward:</strong> {infoWindowData.ward || 'N/A'}</Typography>
                <Typography variant="subtitle2"><strong>Status:</strong> {infoWindowData.status || 'N/A'}</Typography>
                <Typography variant="subtitle2"><strong>Last Log:</strong> {infoWindowData.lastLog || 'N/A'}</Typography>
              </Box>
            </InfoWindow>
          )}
        </GoogleMap>
      </Box>
    </Box>
  );
}

export default Geolocation;
