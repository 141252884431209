import { styled } from '@mui/system';
import Header from '../../../components/Header';
import { useLocation } from 'react-router-dom';
import { tokens } from '../../../theme';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
// import BasicArea from '../Linechart';
import StatBox from '../../../components/StatBox';
import AirOutlinedIcon from '@mui/icons-material/AirOutlined';
import Brightness6Icon from '@mui/icons-material/Brightness6';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
// import { LineChart } from '@mui/x-charts';
import BasicArea from '../../line/index'


const OverlayBoxBottom = styled(Box)(({ theme }) => ({
    // position: 'absolute',
    bottom: 0,
    // left:'50px',
    top:'350px',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    // Ensure overflow is applied to this container
    overflow: 'visible', // or 'auto' or 'scroll' as needed
  }));

  const  Graphical = ()=>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const data = useLocation();
    const deviceId =  data.pathname.split('/').filter(Boolean).pop();
    return(
      <OverlayBoxBottom   >
        <Box  sx={{  display: 'flex',           // Ensure Box is a flex container
          alignItems: 'center',      // Align items vertically
          justifyContent: 'center' , backgroundColor: theme.palette.mode === 'dark'? colors.primary[500] : 'white' }} >   <Header  title={`CONTROLLER ID :  ${deviceId}`} />  </Box>
       
      <Box display="flex" flexDirection="column" gap={2}>
          {/* <Line /> */}
          <BasicArea />
          <Box sx={{ width: 'calc(100% - 20px)', margin: '20px auto' }}>
        <Box
          display="flex"
          flexWrap="wrap"
          gap="10px"
          justifyContent="space-between"
        >
          <Box
            flex="1 1 calc(25% - 10px)"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            minWidth="240px"
          >
            <StatBox
              title="92.21"
              subtitle="CO2 EMISSION REDUCED"
              // progress="0.75"
              // increase="+14%"
              icon={
                <AirOutlinedIcon
                  sx={{ color: colors.greenAccent[600], fontSize: '26px' }}
                />
              }
            />
          </Box>
          <Box
            flex="1 1 calc(25% - 10px)"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            minWidth="240px"
          >
            <StatBox
              title="97.07 KWH"
              subtitle="ENERGY SAVING"
              icon={
                <EnergySavingsLeafIcon
                  sx={{ color: colors.greenAccent[600], fontSize: '26px' }}
                />
              }
            />
          </Box>
          <Box
            flex="1 1 calc(25% - 10px)"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            minWidth="240px"
          >
            <StatBox
              title="7.4"
              subtitle="100% BRIGHTNESS HRS"
              icon={
                <Brightness7Icon
                  sx={{ color: colors.greenAccent[600], fontSize: '26px' }}
                />
              }
            />
          </Box>
          <Box
            flex="1 1 calc(25% - 10px)"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            minWidth="240px"
          >
            <StatBox
              title="3.8"
              subtitle="50% BRIGHTNESS HRS"
              icon={
                <Brightness6Icon
                  sx={{ color: colors.greenAccent[600], fontSize: '26px' }}
                />
              }
            />
          </Box>
        </Box>
      </Box>
      </Box>
    </OverlayBoxBottom>
    )
  }

export default Graphical