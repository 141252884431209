import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LightModeIcon from '@mui/icons-material/LightMode';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SummarizeIcon from '@mui/icons-material/Summarize';
import srslogo from './SRS_LOGO.jpg'



const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selected, setSelected] = useState("Dashboard");
  const navigate = useNavigate();
  const [lineVisible, setLineVisible] = useState(false);
  // const profileimage = localStorage.getItem('profile-image');
  // const parsedimage = JSON.parse(profileimage);

  useEffect(() => {
    setTimeout(() => {
      setLineVisible(true);
    }, 1000);
  }, []);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
        position: 'sticky',
        top: 0,
        height: '100vh',
        zIndex: 1000, 
      }}
    >
      <ProSidebar collapsed={isCollapsed}>  
        <Menu iconShape="square">
          <MenuItem
            onClick={() => {
              localStorage.setItem("toggle", isCollapsed ? "on" : "off");
              setIsCollapsed(!isCollapsed);
            }}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  ADMIN
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={srslogo}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>  
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  Shri Ram Sagar
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  ADMINISTRATOR
                </Typography>
              </Box>
            </Box>
          )}
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Box
              sx={{
                position: "relative",
                "&:hover .dropdown": {
                  display: isCollapsed ? 'flex' : 'none',
                },
              }}
            >
              <Item
                title="Dashboard"
                to="/Admin"
                icon={<HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Box
                className="dropdown"
                sx={{
                  display: 'none',
                  position: 'fixed',
                  left: '0%',
                  top: '82px',
                  backgroundColor: colors.primary[400],
                  boxShadow: '0px 0px 5px rgba(0,0,0,0.2)',
                  zIndex: 2000,
                  padding: '10px',
                  borderRadius: '5px',
                  width: '200px',
                  height: '50px',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '10px',
                  cursor: 'pointer'
                  
                } 
              
              }
              onClick={() => {
                navigate("/Admin");
                console.log("Admin is clicked")
              }
              }
              >
                <HomeOutlinedIcon sx={{ marginLeft : '17px' , color: colors.grey[100]}}/>
                <Typography sx={{ marginLeft: '30px' , color: colors.grey[100] }}  variant="h6
                "
            fontWeight="bold"
            // sx={{ color: colors.grey[100] }}  
                
                >DASHBOARD</Typography>
              </Box>
            </Box>
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              SOLAR STREET LIGHTS
            </Typography>
            <Box
              sx={{
                position: "relative",
                "&:hover .dropdown": {
                  display: isCollapsed ? 'flex' : 'none',
                },
              }}
            >
              <Item
                title="SSLDATA"
                to="SSLDATA"
                icon={<LightModeIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Box
                className="dropdown"
                sx={{
                  display: 'none',
                  position: 'fixed',
                  left: '0%',
                  top: '214.5px',
                  backgroundColor: colors.primary[400],
                  boxShadow: '0px 0px 5px rgba(0,0,0,0.2)',
                  zIndex: 2000,
                  padding: '10px',
                  borderRadius: '5px',
                  width: '228px',
                  height: '50px',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '10px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  navigate("/Admin/SSLDATA");
                  console.log("SSLDATA is clicked")
                }}
              >
                <LightModeIcon sx={{marginLeft : '17px' , color: colors.grey[100]}}/>
                <Typography sx = {{ marginLeft : '24px'  , color: colors.grey[100] }} variant="h6"
  fontWeight="bold">SOLAR STREET LIGHT</Typography>
                <Box
                  className="relative-box"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'absolute',
                    left: '36%',
                    top: '100%',
                    backgroundColor: colors.primary[400],
                    boxShadow: '0px 0px 5px rgba(0,0,0,0.2)',
                    padding: '10px',
                    width: '142px',
                    // zIndex: 0,
                  }}  
                 
                >
                  <Typography variant="body2" sx={{ color: colors.grey[100] }} >SSL DATA</Typography>
                </Box>
              </Box>
            </Box>
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px",  }}
            >
              Reports
            </Typography>
            <Box
              sx={{
                position: "relative",
                "&:hover .dropdown": {
                  display: isCollapsed ? 'flex' : 'none',
                },
              }}
            >
              <Item
                title="LIGHT WORKING DAYS"
                to="singlefaultrectificationlog"
                icon={<AssessmentIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="FAULT RECTIFICATION "
                to="faultrectificationlog"
                icon={<SummarizeIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Box
                className="dropdown"
                sx={{
                  display: 'none',
                  position: 'fixed',
                  left: '0%',
                  top: '302px',
                  backgroundColor: colors.primary[400],
                  boxShadow: '0px 0px 5px rgba(0,0,0,0.2)',
                  zIndex: 2000,
                  padding: '10px',
                  borderRadius: '5px',
                  width: '230px',
                  height: '50px',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '10px',
                  cursor: 'pointer'
                }}
              >
                <AssessmentIcon sx={{marginLeft : '17px' , color: colors.grey[100] }}/>
                <Typography sx={{ marginLeft : '28px' , color: colors.grey[100]  }}  variant="h6"
  fontWeight="bold" >REPORTS</Typography>
                <Box
          className="relative-box"
          sx={{
            // display: showDropdown ? 'flex' : 'none', // Show or hide based on hover
            display : 'flex',
            flexDirection: 'column',
            position: 'absolute', // Absolute positioning relative to the parent Box
            left: '37%', // Position to the right of the dropdown
            top: '50px', // Align with the top of the dropdown
            backgroundColor: colors.primary[400], // Adjust as necessary
            boxShadow: '0px 0px 5px rgba(0,0,0,0.2)',
            padding: '10px',
            // borderRadius: '5px',
            width: '145px', // Adjust width as necessary
            zIndex: -100,
            gap : "5px"
          }}  
        >
          <Typography variant="body2" onClick = { ()=>{
                  navigate("/Admin/singlefaultrectificationlog");
                  console.log("Admin is clicked ")
                }}  sx={{ color: colors.grey[100] }} >LIGHT WORKING DAY</Typography>
<Box 
        component="hr"
        sx={{
          border: 0,
          height: '1px',
          backgroundColor: theme.palette.mode === 'dark' ? 'white' : 'black',
          margin: '10px 0',
          width: '0',
          transition: 'width 5s ease-in-out',
          ...(lineVisible && { width: '100%' }),
        }}
      />
          <Typography variant="body2" onClick = { ()=>{
                  navigate("/Admin/faultrectificationlog");
                  console.log("Admin is clicked ")
                }}  sx={{ color: colors.grey[100] }} >FAULT RECTIFICATION </Typography>
        </Box>
              </Box>
            </Box>
            <Box
              sx={{
                position: "relative",
                "&:hover .dropdown": {
                  display: isCollapsed ? 'flex' : 'none',
                },
              }} >
            <Item
              title="Logout "
              to="/"
              icon={ <ExitToAppIcon />}
              selected={selected}
              setSelected={setSelected}
            />

<Box
  className="dropdown"
  sx={{
    display: 'none', // Hidden by default
    position: 'fixed', // Absolute positioning relative to the parent Box
    left: '0%', // Position it to the right of the parent Box
    top: '392px', // Align it with the top of the parent Box
    backgroundColor: colors.primary[400],
    boxShadow: '0px 0px 5px rgba(0,0,0,0.2)',
    zIndex: 2000,
    padding: '10px',
    borderRadius: '5px',
    width: '200px',
    height: '50px',
    // display: 'flex',
    alignItems: 'center', // Center items vertically
    justifyContent: 'flex-start', // Align items to the start horizontally
    gap: '60px', // Space between icon and text (adjust as needed)
    cursor: 'pointer'
  }}
  onClick={() => {
    navigate("/");
  }}
>
  <ExitToAppIcon  sx={{ marginLeft : '17px' , color: colors.grey[100]}} />
  <Typography  sx={{ color: colors.grey[100] , marginLeft : '-19px' }  
  }  variant ='h6' fontWeight= 'bold'   >LOGOUT</Typography>
</Box>

            </Box>
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};
export default Sidebar;

