
import React, { useRef, useState, useEffect } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { useTheme } from '@emotion/react';
import { tokens } from '../theme';
import { Box } from '@mui/material';
export default function BasicArea() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 500, height: 300 });

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setDimensions({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
        });
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box  sx={{ 
      width: '100%', 
      height: '80vh', 
      maxWidth: 'calc(100% - 100px)', // Adjust based on sidebar width
      boxShadow: 3, 
      borderRadius: 2, 
      overflow: 'hidden', 
      transition: 'max-width 0.3s ease', 
      flexGrow: 1 
    }}  >
    <div ref={containerRef} style={{ width: '100%', height: '100%', position: 'relative' }}>
      <LineChart
        xAxis={[{ data: [1, 2, 3, 4, 5, 6] }]}
        yAxis={[{}]}
        series={[
          {
            data: [2, 2, 0, 0, 4, 4],
            area: true,
          },
        ]}
        width={dimensions.width}
        height={dimensions.height}
      />
      <svg width={dimensions.width} height={dimensions.height} style={{ position: 'absolute', top: 0, left: 0 }}>
        {/* X-axis label */}
        <text   style={{ fill: theme.palette.mode === 'dark' ?  colors.greenAccent[600] : 'black' }}    x={dimensions.width / 2} y={dimensions.height - 10} textAnchor="middle">
          Time
        </text>
        {/* Y-axis label */}
        <text  style={{ fill: theme.palette.mode === 'dark' ?  colors.greenAccent[600] : 'black' }}     x="10" y={dimensions.height / 2} textAnchor="middle" transform={`rotate(-90, 10, ${dimensions.height / 2})`}>
          Power
        </text>
      </svg>  
    </div>

    </Box>
  );
}