import Dashboard from "./scenes/dashboard";
import Login from "./Login";
import Admindashboard from "./Admindashboard";
import Pagenotfound from "./components/Pagenotfound";
import SsLdata from "./scenes/SSL Data/SsLdata";
import Tabularview from "./scenes/SSL Data/Tabularview";
import Faultylight from "./scenes/SSL Data/Faultylight";
import {  Routes, Route } from "react-router-dom";
import Unpluggedlight from "./scenes/SSL Data/Unpluggedlight";
import Healthylight from './scenes/SSL Data/Healthylight';
import Faultrectificationlog from './scenes/SSL Data/Faultrectificationlog';
import Singlefaultrectificationlog from './scenes/SSL Data/Singlefaultrectificationlog';
import SignUp from "./Signup";
import VerifyEmail from './VerifyEmail'
import token from './slices/authSlice'
import Geolocation from "./scenes/SSL Data/Geolocation";
import PrivateRoute from "./components/core/Auth/PrivateRoute";

function App() {
  const tokenvalue = token;
  console.log(tokenvalue)
  return (
    <Routes>
      <Route path="/" element={<Login />} />
          <Route path="/Admin" element={ <PrivateRoute> <Admindashboard /> </PrivateRoute>  }>
          <Route index element={<Dashboard />} />
          <Route path="signin" element = { <SignUp/> } />
          <Route path="verifymail" element={ <VerifyEmail /> } />
          <Route path="SSLDATA" element={<SsLdata />} />
          <Route path="SSLDATA/:Deviceid" element={<Tabularview />} />
          <Route path="faulylights" element={<Faultylight />} />
          <Route path="unpluggedcontroller" element={<Unpluggedlight />} />
          <Route path="healthylights" element={<Healthylight />} />
          <Route path="faultrectificationlog" element={<Faultrectificationlog />} />
          <Route path="singlefaultrectificationlog" element={<Singlefaultrectificationlog />} />
          <Route path="gissinglelocation/:Deviceid" element={ <Geolocation /> } />
        </Route>
      <Route path="*" element={<Pagenotfound />} />
    </Routes>
  );
}
export default App;

