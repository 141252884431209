import { Box  } from "@mui/material";
import {styled} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {Typography} from "@mui/material";
import { tokens } from "../../../theme";
import {useTheme} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";



// import React, { useState } from 'react';
const OverlayBoxBottom = styled(Box)(({ theme }) => ({
    // position: 'absolute',
    bottom: 0,
    // left:'50px',
    top:'350px',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    // Ensure overflow is applied to this container
    overflow: 'visible', // or 'auto' or 'scroll' as needed
  }));

// const ParameterSection  =  ({ deviceId  }) => {
//     // const [dateRange, setDateRange] = useState(null);
//     // const handleDateChange = (newValue) => {
//     //   setDateRange(newValue);
//     // };
//     // const handleExport = () => {
//     //   exportData();
//     // };
    
//   const [parameter,setParameter] = useState([]);

//   let data = [];


//   for (let i = 1; i <= 50; i++) {
//     data.push({
//       id: i,
//       serialNumber: i,
//       date: `2024-06-${i.toString().padStart(2, '0')}`,
//       time: `12:00 PM`,
//       // pvParameters: `PV Param ${i}`,
//       loadOnOff: `Load ${i % 2 === 0 ? 'On' : 'Off'}`,
//       brightnessLevel: `${Math.floor(Math.random() * 100)}%`,
//       pvVoltage: `${(Math.random() * 20 + 10).toFixed(2)}V`,
//       pvCurrent: `${(Math.random() * 5 + 2).toFixed(2)}A`,
//       pvPower: `${(Math.random() * 100).toFixed(2)}W`,
//       batteryVoltage: `${(Math.random() * 12).toFixed(2)}V`,
//       batteryCurrent: `${(Math.random() * 2).toFixed(2)}A`,
//       batteryPower: `${(Math.random() * 20).toFixed(2)}W`,
//       loadVoltage: `${(Math.random() * 10).toFixed(2)}V`,
//       loadCurrent: `${(Math.random() * 1).toFixed(2)}A`,
//       loadPower: `${(Math.random() * 10).toFixed(2)}W`,
//     });
//   }

  

//   const columns = [
//     { field: 'serialNumber', headerName: 'S.No', width: 40 },
//     { field: 'date', headerName: 'Date', width: 90 },
//     { field: 'time', headerName: 'Time', width: 90 },
//     // { field: 'pvParameters', headerName: 'PV Parameters', width: 90 },
//     {
//       field: 'pvVoltage',
//       headerName: 'PV Voltage',
//       width: 90,
//       renderHeader: () => (
//         <Box  
//         display="flex"
//         flexDirection="column"
//         justifyContent="center"
//         alignItems="center"
//         gap={1.5}
//          >
//          {/* <Typography variant="body1">PV Parameters</Typography> */}
//          <Typography variant="body1">PV Voltage</Typography> 

//          {/* <Typography>PV Parameters</Typography>
//           <Typography>PV Voltage</Typography> */}
//         </Box>
//       ),
//     },
//     {
//       field: 'pvCurrent',
//       headerName: 'PV Current',
//       width: 100,
//       renderHeader: () => (
//         <Box   display="flex"
//         flexDirection="column"
//         justifyContent="center"
//         alignItems="center"
//         // gap={1.5}
//         >
//           <Typography>PV Parameters</Typography>
//           <Typography  marginBottom={2} >PV Current</Typography>
//         </Box>
//       ),
//     },
//     {
//       field: 'pvPower',
//       headerName: 'PV Power',
//       width: 90,
//       renderHeader: () => (
//         <Box   display="flex"
//         flexDirection="column"
//         justifyContent="center"
//         alignItems="center"
//         gap={1.5}>
//           {/* <Typography>PV Parameters</Typography> */}
//           <Typography>PV Power</Typography>
//         </Box>
//       ),
//     },
//     {
//       field: 'batteryVoltage',
//       headerName: 'Battery Voltage',
//       width: 100,
//       renderHeader: () => (
//         <Box   display="flex"
//         flexDirection="column"
//         justifyContent="center"
//         alignItems="center"
//         gap={1.5}>
//           {/* <Typography>Battery Parameters</Typography> */}
//           <Typography>Battery Voltage</Typography>
//         </Box>
//       ),
//     },
//     {
//       field: 'batteryCurrent',
//       headerName: 'Battery Current',
//       width: 120,
//       renderHeader: () => (
//         <Box  display="flex"
//         flexDirection="column"
//         justifyContent="center"
//         alignItems="center"
//         // gap={1.5}
//         >
//           <Typography>Battery Parameters</Typography>
//           <Typography  marginBottom={2} >Battery Current</Typography>
//         </Box>
//       ),
//     },
//     {
//       field: 'batteryPower',
//       headerName: 'Battery Power',
//       width: 100,
//       renderHeader: () => (
//         <Box   display="flex"
//         flexDirection="column"
//         justifyContent="center"
//         alignItems="center"
//         gap={1.5}>
//           {/* <Typography>Battery Parameters</Typography> */}
//           <Typography>Battery Power</Typography>
//         </Box>
//       ),
//     },
//     {
//       field: 'loadVoltage',
//       headerName: 'Load Voltage',
//       width: 90,
//       renderHeader: () => (
//         <Box   display="flex"
//         flexDirection="column"
//         justifyContent="center"
//         alignItems="center"
//         gap={1.5}>
//           {/* <Typography>Load Parameters</Typography> */}
//           <Typography>Load Voltage</Typography>
//         </Box>
//       ),
//     },
//     {
//       field: 'loadCurrent',
//       headerName: 'Load Current',
//       width: 110,
//       renderHeader: () => (
//         <Box   display="flex"
//         flexDirection="column"
//         justifyContent="center"
//         alignItems="center"
//         // gap={1.5}
//         >
//           <Typography>Load Parameters</Typography>
//           <Typography marginBottom={2} >Load Current</Typography>
//         </Box>
//       ),
//     },
//     {
//       field: 'loadPower',
//       headerName: 'Load Power',
//       width: 90,
//       renderHeader: () => (
//         <Box   display="flex"
//         flexDirection="column"
//         justifyContent="center"
//         alignItems="center"
//         gap={1.5}>
//           {/* <Typography>Load Parameters</Typography> */}
//           <Typography>Load Power</Typography>
//         </Box>
//       ),
//     },
//   ];

//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);

//   useEffect(()=>{
//       axios.get('http://localhost:4000/api/v1/getparametrs',
//         {
//          params : { deviceid : deviceId }
//         }
//       ).then(
//         (response)=>
//         {
//           console.log(" The parameter date is ",response.data.ParameterData
//             .Parmeters);
//             setParameter(response.data.ParameterData.Parmeters);
//         }
//       ).catch( (error)=>{
//         console.log("Error while loading the api ",error.message)
//       })
//       // setParameter();
//   },[deviceId]);


//   return (
//     <OverlayBoxBottom  theme={theme}  colors = { colors} >
//       <Box display="flex" flexDirection="column" gap={2}>
//         <Box sx={{ height: 400, width: '100%'  , backgroundColor: theme.palette.mode === 'dark'? colors.primary[500] : 'white', }}>
//           <DataGrid
//             initialState={{
//               pagination: { paginationModel: { pageSize: 15, page: 0 } },
//             }}
//             pageSizeOptions={[5, 10, 25]}
//             rows={data}
//             columns={columns}
//             disableSelectionOnClick
//             autoPageSize
//           />
//         </Box>
//       </Box>
//     </OverlayBoxBottom>
//   );

//   }

const ParameterSection = ({ deviceId }) => {
  const [parameter, setParameter] = useState([]);

  const columns = [
    { field: 'sNo', headerName: 'S.No', width: 40 },
    { field: 'date', headerName: 'Date', width: 90 },
    { field: 'time', headerName: 'Time', width: 90 },
    {
      field: 'pvVoltage',
      headerName: 'PV Voltage',
      width: 90,
      renderHeader: () => (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={1.5}>
          <Typography variant="body1">PV Voltage</Typography>
        </Box>
      ),
    },
    {
      field: 'pvCurrent',
      headerName: 'PV Current',
      width: 100,
      renderHeader: () => (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography>PV Parameters</Typography>
          <Typography marginBottom={2}>PV Current</Typography>
        </Box>
      ),
    },
    {
      field: 'pvPower',
      headerName: 'PV Power',
      width: 90,
      renderHeader: () => (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={1.5}>
          <Typography>PV Power</Typography>
        </Box>
      ),
    },
    {
      field: 'batteryVoltage',
      headerName: 'Battery Voltage',
      width: 100,
      renderHeader: () => (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={1.5}>
          <Typography>Battery Voltage</Typography>
        </Box>
      ),
    },
    {
      field: 'batteryCurrent',
      headerName: 'Battery Current',
      width: 120,
      renderHeader: () => (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography>Battery Parameters</Typography>
          <Typography marginBottom={2}>Battery Current</Typography>
        </Box>
      ),
    },
    {
      field: 'batteryPower',
      headerName: 'Battery Power',
      width: 100,
      renderHeader: () => (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={1.5}>
          <Typography>Battery Power</Typography>
        </Box>
      ),
    },
    {
      field: 'loadVoltage',
      headerName: 'Load Voltage',
      width: 90,
      renderHeader: () => (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={1.5}>
          <Typography>Load Voltage</Typography>
        </Box>
      ),
    },
    {
      field: 'loadCurrent',
      headerName: 'Load Current',
      width: 110,
      renderHeader: () => (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography>Load Parameters</Typography>
          <Typography marginBottom={2}>Load Current</Typography>
        </Box>
      ),
    },
    {
      field: 'loadPower',
      headerName: 'Load Power',
      width: 90,
      renderHeader: () => (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={1.5}>
          <Typography>Load Power</Typography>
        </Box>
      ),
    },
  ];

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    axios
      .get('http://localhost:4000/api/v1/getparametrs', {
        params: { deviceid: deviceId },
      })
      .then((response) => {
        console.log("The parameter data is ", response.data.ParameterData.Parmeters);
        const fetchedData = response.data.ParameterData.Parmeters.map((item, index) => ({
          ...item,
          id: index + 1, // Adding an id to each item
        }));
        setParameter(fetchedData);
      })
      .catch((error) => {
        console.log("Error while loading the API ", error.message);
      });
  }, [deviceId]);

  return (
    <OverlayBoxBottom theme={theme} colors={colors}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box sx={{ height: 400, width: '100%', backgroundColor: theme.palette.mode === 'dark' ? colors.primary[500] : 'white' }}>
          <DataGrid
            initialState={{
              pagination: { paginationModel: { pageSize: 15, page: 0 } },
            }}
            pageSizeOptions={[5, 10, 25]}
            rows={parameter}
            columns={columns}
            disableSelectionOnClick
            autoPageSize
          />
        </Box>
      </Box>
    </OverlayBoxBottom>
  );
};

export default ParameterSection;


  // export default ParameterSection


//   batteryCurrent
// : 
// 4
// batteryPower
// : 
// 60
// batteryVoltage
// : 
// 15
// brightnessLevel
// : 
// 50
// date
// : 
// "2024-08-08T00:00:00.000Z"
// loadCurrent
// : 
// 10
// loadOnOff
// : 
// false
// loadPower
// : 
// 342
// loadVoltage
// : 
// 10
// pvCurrent
// : 
// 19
// pvPower
// : 
// 100
// pvVoltage
// : 
// 18
// sNo
// : 
// 5