// import React, { useEffect } from 'react'
// import { DataGrid } from '@mui/x-data-grid';
// import {  Typography } from '@mui/material';
// import { Box } from '@mui/material';
// import { useState  } from 'react';
// // import { useEffect } from 'react';
// import axios from 'axios';


// const columns = [
// { field: 'id', headerName: 'S.No', width: 90 },
// { field: 'deviceId', headerName: 'Device ID', width: 150 },
// { field: 'poleSerialNo', headerName: 'Pole Serial No.', width: 150 },
// { field: 'faultGenerated', headerName: 'Fault Generated Date/Time', width: 200 },
// { field: 'faultType', headerName: 'Fault Type', width: 150 },
// { field: 'faultRectified', headerName: 'Fault Rectification Date/Time', width: 200 },
// ];

// const randomDate = () => {
// const start = new Date(2023, 0, 1);
// const end = new Date();
// return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
// };

// const generateRandomData = (numRows) => {
//     const faultTypes = ['Power Failure', 'Bulb Issue', 'Solar Panel Issue', 'Wiring Fault'];
//     return Array.from({ length: numRows }, (_, index) => ({
//       id: index + 1,
//       deviceId: `86827704855${index}03`,
//       poleSerialNo: `PSN${Math.floor(Math.random() * 100000)}`,
//       faultGenerated: randomDate().toLocaleString(),
//       faultType: faultTypes[Math.floor(Math.random() * faultTypes.length)],
//       faultRectified: randomDate().toLocaleString(),
//     }));
//   };

  

// const rows = generateRandomData(50);
// function Faultrectificationlog() {
//   const [loading, setLoading] = useState(false); // State to manage loading state
//   // const [pageSize, setPageSize] = useState(10); // State to manage page size
//   const [currentPage, setCurrentPage] = useState(1); // State to manage current page
//   const rowsPerPageOptions = [10, 20, 25, 50]; // Define rows per page options
//   // Function to handle page change
//   const [pageSize, setPageSize] = useState(10); // 
//   const handlePageChange = (newPage) => {
//     setLoading(true); // Set loading to true when page changes
//     setTimeout(() => {
//       setCurrentPage(newPage);
//       setLoading(false); // Set loading to false after 0.5 seconds
//       // Example: Simulate API call or data update based on newPage and pageSize
//       console.log(`Page changed to ${newPage}`);
//     }, 500); // Simulate 0.5 seconds delay
//   };
//   const [faultdata,setFaultdata] = useState([]);

//   useEffect( ()=>{
//       axios.get()
//   },[faultdata])

//   // Function to handle rows per page change
//   const handlePageSizeChange = (newPageSize) => {
//     setLoading(true); // Set loading to true when page size changes
//     setTimeout(() => {
//       setPageSize(newPageSize);
//       setCurrentPage(1); // Reset current page when changing page size
//       setLoading(false); // Set loading to false after 0.5 seconds
//       // Example: Implement logic to fetch data with new page size
//       console.log(`Rows per page changed to ${newPageSize}`);
//     }, 500); // Simulate 0.5 seconds delay
//   };

  
//   return (
//     <Box m="20px">
//         <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Box><Typography variant='h3'>Solar Street Light Data</Typography></Box>
//         <Box>
//           <Typography variant='h6'>
//             Dashboard - Fault Rectification Logs
//             </Typography>
//         </Box>
//       </Box>
//     <div style={{ height: 500, width: '100%' }}>
//       <DataGrid rows={rows} columns={columns}   pageSize={pageSize}
//       rowsPerPageOptions={rowsPerPageOptions}
//       pagination
//       onPageChange={handlePageChange}
//       onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
//       loading={loading} // Pass loading state to DataGrid
//       // autoHeight={true}
//       virtualization={true}
//       currentPage={currentPage}  sx={{
//             '& .MuiDataGrid-columnHeaders': {     
//               top: 0,          
//             },
//           }} />
//     </div>
//   </Box>
//   )
// }

// export default Faultrectificationlog

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { DataGrid } from '@mui/x-data-grid';
// import { Box, Typography } from '@mui/material';

// function Faultrectificationlog() {
//   const [loading, setLoading] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(10);
//   const [totalRows, setTotalRows] = useState(0);
//   const [faultdata, setFaultdata] = useState([]);

//   const rowsPerPageOptions = [10, 20, 25, 50];

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         const response = await axios.get(
//           `https://solarstreetlightyojana.com/reports/fault-rectification-logs/data`, 
//           {
//             params: {
//               draw: currentPage,
//               length: pageSize,
//               'search[regex]': false,
//             }
//           }
//         );
//         setFaultdata(response.data.data);  // Assuming data is in `data` key
//         setTotalRows(response.data.recordsTotal);  // Set total records
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//       setLoading(false);
//     };
//     fetchData();
//   }, [currentPage, pageSize]);

//   const handlePageChange = (newPage) => {
//     setCurrentPage(newPage + 1);  // DataGrid's page is 0-indexed
//   };

//   const handlePageSizeChange = (newPageSize) => {
//     setPageSize(newPageSize);
//     setCurrentPage(1);  // Reset to first page
//   };

//   const columns = [
//     { field: 'id', headerName: 'ID', width: 150 },
//     { field: 'faultDetails', headerName: 'Fault Details', width: 200 },
//     // Add more columns based on your data structure
//   ];

//   const rows = faultdata.map((row, index) => ({
//     id: index + 1,
//     ...row,
//   }));

//   return (
//     <Box m="20px">
//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Typography variant='h3'>Solar Street Light Data</Typography>
//         <Typography variant='h6'>Dashboard - Fault Rectification Logs</Typography>
//       </Box>
//       <div style={{ height: 500, width: '100%' }}>
//         <DataGrid 
//           rows={rows} 
//           columns={columns} 
//           pageSize={pageSize}
//           rowsPerPageOptions={rowsPerPageOptions}
//           paginationMode="server"
//           rowCount={totalRows}
//           onPageChange={handlePageChange}
//           onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
//           loading={loading}
//           currentPage={currentPage - 1}  // DataGrid expects 0-indexed page
//           sx={{ '& .MuiDataGrid-columnHeaders': { top: 0 } }}
//         />
//       </div>
//     </Box>
//   );
// }

// export default Faultrectificationlog;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';

function Faultrectificationlog() {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [faultdata, setFaultdata] = useState([]);

  const rowsPerPageOptions = [10, 20, 25, 50];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://solarstreetlightyojana.com/reports/fault-rectification-logs/data`, 
          {
            params: {
              draw: currentPage,
              length: pageSize,
              'search[regex]': false,
            }
          }
        ).then(
          (resp)=>{
            console.log("The response data is ",resp)
          }
        );
        // Map the nested array data to objects with keys for DataGrid
        const formattedData = response.data.data.map((item, index) => ({
          id: index + 1 + (currentPage - 1) * pageSize,  // Unique ID for each row
          imei: item[0],
          poleSerialNo: item[1],
          luminaireSerialNo: item[2],
          panelSerialNo: item[3],
          batterySerialNo: item[4],
          installationDate: item[5],
          healthyWorkingDays: item[6],
          faultyDays: item[7]
        }));
        
        setFaultdata(formattedData);  // Set the formatted data
        setTotalRows(response.data.recordsTotal);  // Set total records
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };
    fetchData();
  }, [currentPage, pageSize]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage + 1);  // DataGrid's page is 0-indexed
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setCurrentPage(1);  // Reset to first page
  };

  const columns = [
    { field: 'imei', headerName: 'IMEI', width: 150 },
    { field: 'poleSerialNo', headerName: 'Pole Serial No.', width: 200 },
    { field: 'luminaireSerialNo', headerName: 'Luminaire Serial No.', width: 200 },
    { field: 'panelSerialNo', headerName: 'Panel Serial No.', width: 200 },
    { field: 'batterySerialNo', headerName: 'Battery Serial No.', width: 200 },
    { field: 'installationDate', headerName: 'Installation Date', width: 150 },
    { field: 'healthyWorkingDays', headerName: 'Healthy Working Days', width: 150 },
    { field: 'faultyDays', headerName: 'Faulty Days', width: 150 },
  ];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant='h3'>Solar Street Light Data</Typography>
        <Typography variant='h6'>Dashboard - Fault Rectification Logs</Typography>
      </Box>
      <div style={{ height: 500, width: '100%' }}>
        <DataGrid 
          rows={faultdata} 
          columns={columns} 
          pageSize={pageSize}
          rowsPerPageOptions={rowsPerPageOptions}
          paginationMode="server"
          rowCount={totalRows}
          onPageChange={handlePageChange}
          onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
          loading={loading}
          currentPage={currentPage - 1}  // DataGrid expects 0-indexed page
          sx={{ '& .MuiDataGrid-columnHeaders': { top: 0 } }}
        />
      </div>
    </Box>
  );
}

export default Faultrectificationlog;
