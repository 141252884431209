// import React, { useEffect } from 'react';
// import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
// import { IconButton } from '@mui/material';
// import TableChartIcon from '@mui/icons-material/TableChart';
// import { Box, Typography } from '@mui/material';
// import { useState } from 'react';
// import axios from 'axios';
// import * as XLSX from 'xlsx';
// import {saveAs }  from 'file-saver'
// import {Button} from '@mui/material';

// function CustomToolbar({ rows, columns }) {
//   const handleExportExcel = () => {
//     const ws = XLSX.utils.json_to_sheet(rows.map((row) => {
//       const newRow = {};
//       columns.forEach((col) => {
//         newRow[col.headerName] = row[col.field];
//       });
//       return newRow;
//     }));
//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//     const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
//     saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'data.xlsx');
//   };

//   const handleExportCSV = () => {
//     const csvData = XLSX.utils.json_to_sheet(rows.map((row) => {
//       const newRow = {};
//       columns.forEach((col) => {
//         newRow[col.headerName] = row[col.field];
//       });
//       return newRow;
//     }));
//     const csv = XLSX.utils.sheet_to_csv(csvData);
//     const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
//     saveAs(blob, 'data.csv');
//   };

//   return (
//     <GridToolbarContainer 
//       sx={{ 
//         bgcolor: 'white', 
//         justifyContent: 'center', 
//         padding: 1, 
//         display: 'flex', 
//         gap: 2 
//       }}
//     >
//       {/* <GridToolbarExport /> */}
//       <Button 
//         onClick={handleExportExcel} 
//         variant="contained" 
//         color="primary" 
//         sx={{ 
//           display: 'flex', 
//           alignItems: 'center', 
//           justifyContent: 'center', 
//           padding: 1,
//           fontSize: '16px',
//           textTransform: 'none' // Prevents uppercase transformation
//         }}
//       >
//         <Typography variant='body2' sx={{ color: 'white', marginRight: 1 }}>
//           Export xlsx
//         </Typography>
//       </Button>

//       <Button 
//         onClick={handleExportCSV} 
//         variant="contained" 
//         color="primary" 
//         sx={{ 
//           display: 'flex', 
//           alignItems: 'center', 
//           justifyContent: 'center', 
//           padding: 1,
//           fontSize: '16px',
//           textTransform: 'none' // Prevents uppercase transformation
//         }}
//       >
//         <Typography variant='body2' sx={{ color: 'white', marginRight: 1 }}>
//           Export CSV
//         </Typography>
//       </Button>
//     </GridToolbarContainer>
//   );
// }
// // Custom cell renderer for the 'Tabular Data' column

// function TabularDataCell(params) {
//   const handleClick = () => {
//     console.log("This is the device id of that light ",params.row.DeviceID)
//     window.open(`${window.location.pathname}/${params.row.DeviceID}`, '_blank');
//   };

//   return (
//     <IconButton onClick={handleClick} sx={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', 
//       color: 'white', 
//         padding: '10px', 
//         '&:hover': {
//           background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
//         },
//         borderRadius: '10px',
//     }} >
//       <TableChartIcon />
//     </IconButton>
//   );
// }

// function Ssldata() {
//   const [loading, setLoading] = useState(false); // State to manage loading state
//   const [pageSize, setPageSize] = useState(10); // State to manage page size
//   const [currentPage, setCurrentPage] = useState(1); // State to manage current page
//   const rowsPerPageOptions = [10, 20, 25, 50]; // Define rows per page options
//   const [ssladata ,  setSSLdata ] = useState([]);
//   const columns = [
//     { field: 'Deviceid', headerName: 'Device ID-IMEI', width: 150 },
//     { field: 'Rms_mobile_no', headerName: 'RMS Mobile No', width: 150 },
//     { field: 'Pole_sr_no', headerName: 'Pole Serial No', width: 150 },
//     { field: 'Luminiare_sr_no', headerName: 'Luminiare Sr. No.', width: 150 },
//     { field: 'Panel_sr_no', headerName: 'Panel Sr. No.', width: 150 },
//     { field: 'Battery_sr_no', headerName: 'Battery Sr. No.', width: 150 },
//     { field: 'Last_Update', headerName: 'Last Updated', width: 150 },
//     { field: 'tabularData', headerName: 'Tabular Data', width: 150, renderCell: TabularDataCell, sortable: false, filterable: false },
//   ];

//   // Function to handle page change
//   const handlePageChange = (newPage) => {
//     setLoading(true); // Set loading to true when page changes
//     setTimeout(() => {
//       setCurrentPage(newPage);
//       setLoading(false); // Set loading to false after 0.5 seconds
//       // Example: Simulate API call or data update based on newPage and pageSize
//       console.log(`Page changed to ${newPage}`);
//     }, 500); // Simulate 0.5 seconds delay
//   };

//   // Function to handle rows per page change
//   const handlePageSizeChange = (newPageSize) => {
//     setLoading(true); // Set loading to true when page size changes
//     setTimeout(() => {
//       setPageSize(newPageSize);
//       setCurrentPage(1); // Reset current page when changing page size
//       setLoading(false); // Set loading to false after 0.5 seconds
//       // Example: Implement logic to fetch data with new page size
//       console.log(`Rows per page changed to ${newPageSize}`);
//     }, 500); // Simulate 0.5 seconds delay
//   };

//   // Generate rows with dummy data
//   const rows = ssladata

//   console.log(" The row data ",rows);

//   useEffect( ()=>{
//     axios.get(' http://localhost:4000/api/v1/testpoint',{
//       withCredentials :  true 
//     }).then(
//       (response) => { 
//         console.log(response.data.data);
//         setSSLdata( response.data.data);
//         }   
//     ).catch( (error)=>{console.log(error)} )
//   } ,[])

//   console.log("This is the ssldata ",ssladata)

//   return (
//     <Box m="20px">
//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Typography variant='h3'>Solar Street Light Data</Typography>
//         <Typography variant='h6'>Dashboard - Solar Street Light Data</Typography>
//       </Box>
//       <div style={{ height: '80vh', width: '100%' }}>
//         <DataGrid 
//           getRowId={(rows) => rows._id}
//           rows={ssladata}
//           columns={columns}
//           pageSize={pageSize}
//           components={{Toolbar: () => <CustomToolbar rows={ssladata} columns={columns} />}}
//           rowsPerPageOptions={rowsPerPageOptions} // Use defined rows per page options
//           pagination
//           onPageChange={handlePageChange}
//           onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
//           loading={loading} // Pass loading state to DataGrid
//           // autoHeight={true}
//           virtualization={true}
//           currentPage={currentPage}
//           sx={{
//             '& .MuiDataGrid-columnHeaders': {     
//               top: 0,          
//             },
//           }}
//         />
//       </div>
//     </Box>
//   );
// }
// export default Ssldata;



// import React, { useState, useEffect } from 'react';
// import { Box, Typography, IconButton } from '@mui/material';
// import { DataGrid } from '@mui/x-data-grid';
// import TableChartIcon from '@mui/icons-material/TableChart';
// import axios from 'axios';
// import * as XLSX from 'xlsx';
// import {saveAs }  from 'file-saver'
// import {Button} from '@mui/material';
// import {  GridToolbarContainer } from '@mui/x-data-grid';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import { useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchDataStart, fetchDataSuccess, fetchDataFailure, setPage, setPageSize } from '../../slices/ssldataSlice';


// function CustomToolbar({ rows, columns }) {
//   const handleExportExcel = () => {
//     const ws = XLSX.utils.json_to_sheet(rows.map((row) => {
//       const newRow = {};
//       columns.forEach((col) => {
//         newRow[col.headerName] = row[col.field];
//       });
//       return newRow;
//     }));
//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//     const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
//     saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'data.xlsx');
//   };

//   const handleExportCSV = () => {
//     const csvData = XLSX.utils.json_to_sheet(rows.map((row) => {
//       const newRow = {};
//       columns.forEach((col) => {
//         newRow[col.headerName] = row[col.field];
//       });
//       return newRow;
//     }));
//     const csv = XLSX.utils.sheet_to_csv(csvData);
//     const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
//     saveAs(blob, 'data.csv');
//   };

//   return (
//     <GridToolbarContainer 
//       sx={{ 
//         bgcolor: 'white', 
//         justifyContent: 'center', 
//         padding: 1, 
//         display: 'flex', 
//         gap: 2 
//       }}
//     >
//       {/* <GridToolbarExport /> */}
//       <Button 
//         onClick={handleExportExcel} 
//         variant="contained" 
//         color="primary" 
//         sx={{ 
//           display: 'flex', 
//           alignItems: 'center', 
//           justifyContent: 'center', 
//           padding: 1,
//           fontSize: '16px',
//           textTransform: 'none' // Prevents uppercase transformation
//         }}
//       >
//         <Typography variant='body2' sx={{ color: 'white', marginRight: 1 }}>
//           Export xlsx
//         </Typography>
//       </Button>

//       <Button 
//         onClick={handleExportCSV} 
//         variant="contained" 
//         color="primary" 
//         sx={{ 
//           display: 'flex', 
//           alignItems: 'center', 
//           justifyContent: 'center', 
//           padding: 1,
//           fontSize: '16px',
//           textTransform: 'none' // Prevents uppercase transformation
//         }}
//       >
//         <Typography variant='body2' sx={{ color: 'white', marginRight: 1 }}>
//           Export CSV
//         </Typography>
//       </Button>
//     </GridToolbarContainer>
//   );
// }

// function TabularDataCell(params) {
//   const handleClick = () => {
//     console.log("This is the device id of that light ", params.row.Deviceid); // Ensure 'Deviceid' matches the field name in your data
//     window.open(`${window.location.pathname}/${params.row.Deviceid}`, '_blank');
//   };

//   return (
//     <IconButton 
//       onClick={handleClick} 
//       sx={{ 
//         background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', 
//         color: 'white', 
//         padding: '10px', 
//         '&:hover': {
//           background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
//         },
//         borderRadius: '10px',
//       }} 
//     >
//       <TableChartIcon />
//     </IconButton>
//   );
// }

// function Geolocationcell(params){
//   const navigate = useNavigate()
//   const handleClick = () => {
//     navigate(`/Admin/gissinglelocation/${params.row.Deviceid}`);
//   };

//   return (
//     <IconButton onClick={handleClick} sx={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', 
//       color: 'white', 
//         padding: '10px', 
//         '&:hover': {
//           background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
//         },
//         borderRadius: '10px',
//     }} >
//       <LocationOnIcon />
//     </IconButton>
//   );
// }

// function Ssldata() {
//   const [loading, setLoading] = useState(false); // State to manage loading state
//   const [pageSize, setPageSize] = useState(10); // State to manage page size
//   const [currentPage, setCurrentPage] = useState(1); // State to manage current page
//   const rowsPerPageOptions = [10, 20, 25, 50]; // Define rows per page options
//   const dispatch = useDispatch();
//   const ssldata = useSelector((state) => state.ssldata.data);
//   // const loading = useSelector((state) => state.ssldata.loading);
//   // const currentPage = useSelector((state) => state.ssldata.currentPage);
//   // const pageSize = useSelector((state) => state.ssldata.pageSize);
//   const totalItems = useSelector((state) => state.ssldata.totalItems);
  
//   const columns = [
//     { field: 'Deviceid', headerName: 'Device ID-IMEI', width: 150 },
//     { field: 'Rms_mobile_no', headerName: 'RMS Mobile No', width: 150 },
//     { field: 'Pole_sr_no', headerName: 'Pole Serial No', width: 150 },
//     { field: 'Luminiare_sr_no', headerName: 'Luminiare Sr. No.', width: 150 },
//     { field: 'Panel_sr_no', headerName: 'Panel Sr. No.', width: 150 },
//     { field: 'Battery_sr_no', headerName: 'Battery Sr. No.', width: 150 },
//     { field: 'Last_Update', headerName: 'Last Updated', width: 150 },
//     { field: 'tabularData', headerName: 'Tabular Data', width: 150, renderCell: 
//       TabularDataCell, sortable: false, filterable: false },
//       { field: 'geolocation', headerName: 'GeoLocation Data', width: 150, renderCell: Geolocationcell, sortable: false, filterable: false },
//   ];

//   // Function to handle page change
//   const handlePageChange = (newPage) => {
//     setLoading(true); // Set loading to true when page changes
//     setTimeout(() => {
//       setCurrentPage(newPage);
//       setLoading(false); // Set loading to false after 0.5 seconds
//       // Example: Simulate API call or data update based on newPage and pageSize
//       console.log(`Page changed to ${newPage}`);
//     }, 500); // Simulate 0.5 seconds delay
//   };

//   // Function to handle rows per page change
//   const handlePageSizeChange = (newPageSize) => {
//     setLoading(true); // Set loading to true when page size changes
//     setTimeout(() => {
//       setPageSize(newPageSize);
//       setCurrentPage(1); // Reset current page when changing page size
//       setLoading(false); // Set loading to false after 0.5 seconds
//       // Example: Implement logic to fetch data with new page size
//       console.log(`Rows per page changed to ${newPageSize}`);
//     }, 500); // Simulate 0.5 seconds delay
//   };

//   // useEffect(() => {
//   //   axios.get('http://localhost:4000/api/v1/testpoint', {
//   //     withCredentials: true 
//   //   }).then(
//   //     (response) => { 
//   //       console.log(response.data.data);
//   //       setSSLdata(response.data.data);
//   //     }   
//   //   ).catch((error) => { 
//   //     console.log(error);
//   //   });
//   // }, []);

//   useEffect(() => {
//     dispatch(fetchDataStart());
//     axios.get(`http://localhost:4000/api/v1/testpoint`, { withCredentials: true })
//       .then((response) => {
//         console.log(response.data.data);
//         dispatch(fetchDataSuccess({
//           data: response.data.data,
//           totalItems: response.data.data.length,
//         }));
//       })
//       .catch((error) => {
//         dispatch(fetchDataFailure(error.toString()));
//       });
//   }, [dispatch]);

//   return (
//     <Box m="20px">
//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Typography variant='h3'>Solar Street Light Data</Typography>
//         <Typography variant='h6'>Dashboard - Solar Street Light Data</Typography>
//       </Box>
//       <div style={{ height: '80vh', width: '100%' }}>
//         <DataGrid 
//           getRowId={(row) => row._id}
//           rows={ssldata}
//           columns={columns}
//           pageSize={pageSize}
//           components={{Toolbar: () => <CustomToolbar rows={ssldata} columns={columns} />}}
//           rowsPerPageOptions={rowsPerPageOptions} // Use defined rows per page options
//           pagination
//           onPageChange={handlePageChange}
//           onPageSizeChange={(params) => handlePageSizeChange(params.pageSize)}
//           loading={loading} // Pass loading state to DataGrid
//           // autoHeight={true}
//           virtualization={true}
//           currentPage={currentPage}
//           sx={{
//             '& .MuiDataGrid-columnHeaders': {     
//               top: 0,          
//             },
//           }}
//         />
//       </div>
//     </Box>
//   );
// }

// export default Ssldata;


import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Button } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import TableChartIcon from '@mui/icons-material/TableChart';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {  fetchDataSuccess, fetchDataFailure } from '../../slices/ssldataSlice';
import { useMemo } from 'react';
import { useRef } from 'react';

// const  CustomToolbar = React.memo(   ({ rows, columns , onSearch, searchTerm , inputRef  }) => {
//   const handleExportExcel = () => {
//     const ws = XLSX.utils.json_to_sheet(rows.map((row) => {
//       const newRow = {};
//       columns.forEach((col) => {
//         newRow[col.headerName] = row[col.field];
//       });
//       return newRow;
//     }));
//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//     const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
//     saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'data.xlsx');
//   };

//   const handleExportCSV = () => {
//     const csvData = XLSX.utils.json_to_sheet(rows.map((row) => {
//       const newRow = {};
//       columns.forEach((col) => {
//         newRow[col.headerName] = row[col.field];
//       });
//       return newRow;
//     }));
//     const csv = XLSX.utils.sheet_to_csv(csvData);
//     const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
//     saveAs(blob, 'data.csv');
//   };

//   const handleSearchChange = (event) => {
//     const newSearchTerm = event.target.value;
//     onSearch(newSearchTerm);
//   };

//   useEffect(() => {
//     // Automatically focus on the search input box when the component renders
//     if (inputRef.current) {
//       inputRef.current.focus();
//     }
//   }, [inputRef]);



//   return (
//     <GridToolbarContainer
//       sx={{
//         bgcolor: 'white',
//         justifyContent: 'center',
//         padding: 1,
//         display: 'flex',
//         gap: 2,
//       }}
//     >
//       <Button
//         onClick={handleExportExcel}
//         variant="contained"
//         color="primary"
//         sx={{
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           padding: 1,
//           fontSize: '16px',
//           textTransform: 'none',
//         }}
//       >
//         <Typography variant='body2' sx={{ color: 'white', marginRight: 1 }}>
//           Export xlsx
//         </Typography>
//       </Button>

//       <Button
//         onClick={handleExportCSV}
//         variant="contained"
//         color="primary"
//         sx={{
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           padding: 1,
//           fontSize: '16px',
//           textTransform: 'none',
//         }}
//       >
//         <Typography variant='body2' sx={{ color: 'white', marginRight: 1 }}>
//           Export CSV
//         </Typography>
//       </Button>

//         <input 
//         type='text'
//         placeholder='Search Device Id'
//         value={searchTerm} // Controlled input
//         onChange={handleSearchChange}
//         style={{padding:'8px' , fontSize : '16px'}}
//         ref={inputRef}
//         />

//     </GridToolbarContainer>
//   );
// })

function TabularDataCell({ params }) {
  const handleClick = () => {
    console.log("This is the device id of that light ", params.row.Deviceid);
    window.open(`${window.location.pathname}/${params.row.Deviceid}`, '_blank');
  };

  return (
    <IconButton
      onClick={handleClick}
      sx={{
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        color: 'white',
        padding: '10px',
        '&:hover': {
          background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
        },
        borderRadius: '10px',
      }}
    >
      <TableChartIcon />
    </IconButton>
  );
}

function Geolocationcell({ params }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/Admin/gissinglelocation/${params.row.Deviceid}`);
  };
  return (
    <IconButton
      onClick={handleClick}
      sx={{
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        color: 'white',
        padding: '10px',
        '&:hover': {
          background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
        },
        borderRadius: '10px',
      }}
    >
      <LocationOnIcon />
    </IconButton>
  );
}

// function Ssldata() {

//   const [loading, setLoading] = useState(false);
//   const [pageSize, setPageSize] = useState(10);
//   const [currentPage, setCurrentPage] = useState(1);
//   const dispatch = useDispatch();
//   const ssldata = useSelector((state) => state.ssldata.data);
//   const [searchTerm, setSearchTerm] = useState('');
//   const searchInputRef = useRef(null);
//   const [filterModel, setFilterModel] = useState({ items: [] });
//   // const totalItems = useSelector((state) => state.ssldata.totalItems);
// console.log(currentPage)
//   const columns = [
//     { field: 'Deviceid', headerName: 'Device ID-IMEI', width: 150 },
//     { field: 'Rms_mobile_no', headerName: 'RMS Mobile No', width: 150 },
//     { field: 'Pole_sr_no', headerName: 'Pole Serial No', width: 150 },
//     { field: 'Luminiare_sr_no', headerName: 'Luminiare Sr. No.', width: 150 },
//     { field: 'Panel_sr_no', headerName: 'Panel Sr. No.', width: 150 },
//     { field: 'Battery_sr_no', headerName: 'Battery Sr. No.', width: 150 },
//     { field: 'Last_Update', headerName: 'Last Updated', width: 150 },
//     {
//       field: 'tabularData',
//       headerName: 'Tabular Data',
//       width: 150,
//       renderCell: (params) => <TabularDataCell params={params} />,
//       sortable: false,
//       filterable: false,
//     },
//     {
//       field: 'geolocation',
//       headerName: 'GeoLocation Data',
//       width: 150,
//       renderCell: (params) => <Geolocationcell params={params} />,
//       sortable: false,
//       filterable: false,
//     },
//   ];

//   useEffect(() => {
//     dispatch(fetchDataStart());
//     axios
//       .get(`http://localhost:4000/api/v1/testpoint`, { withCredentials: true })
//       .then((response) => {
//         console.log(response.data.data);
//         dispatch(
//           fetchDataSuccess({
//             data: response.data.data,
//             totalItems: response.data.data.length,
//           })
//         );
//       })
//       .catch((error) => {
//         dispatch(fetchDataFailure(error.toString()));
//       });
//   }, [dispatch]);

//   const handlePageChange = (newPage) => {
//     setLoading(true);
//     setTimeout(() => {
//       setCurrentPage(newPage);
//       setLoading(false);
//       console.log(`Page changed to ${newPage}`);
//     }, 500);
//   };

//   const handlePageSizeChange = (newPageSize) => {
//     setLoading(true);
//     setTimeout(() => {
//       setPageSize(newPageSize);
//       setCurrentPage(1);
//       setLoading(false);
//       console.log(`Rows per page changed to ${newPageSize}`);
//     }, 500);
//   };

//   const filteredRows = useMemo(() => {
//     if (filterModel.items.length > 0) {
//       return ssldata.filter(row =>
//         filterModel.items.every(filter => {
//           const value = row[filter.columnField];
//           return value && value.toLowerCase().includes(filter.value.toLowerCase());
//         })
//       );
//     }
//     return ssldata;
//   }, [ssldata, filterModel]);

//   const searchedRows = useMemo(() => {
//     if (searchTerm) {
//       return filteredRows.filter(row =>
//         row.DeviceID.toLowerCase().includes(searchTerm.toLowerCase())
//       );
//     }
//     return filteredRows;
//   }, [filteredRows, searchTerm]);


//   const handleSearch = (term) => {
//     setSearchTerm(term);
//   };

//   useEffect(() => {
//     // Automatically focus on the search input whenever the component renders
//     if (searchInputRef.current) {
//       searchInputRef.current.focus();
//     }
//   }, [loading]); // This ensures the focus is set after data is loaded


//   return (
//     <Box m="20px">
//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Typography variant='h3'>Solar Street Light Data</Typography>
//         <Typography variant='h6'>Dashboard - Solar Street Light Data</Typography>
//       </Box>
//       <div style={{ height: '80vh', width: '100%' }}>
//         <DataGrid
//           getRowId={(row) => row._id}
//           rows={ssldata}
//           columns={columns}
//           pageSize={pageSize}
//           components={{
//             Toolbar: () => <CustomToolbar rows={ssldata} columns={columns}
//             searchTerm={searchTerm}
//                 onSearch={handleSearch}
//                 inputRef={searchInputRef}               
//             />,
//           }}
//           filterModel={filterModel}
//           onFilterModelChange={setFilterModel}
//           rowsPerPageOptions={[10, 20, 25, 50]}
//           pagination
//           onPageChange={(params) => handlePageChange(params.page)}
//           onPageSizeChange={(params) => handlePageSizeChange(params.pageSize)}
//           loading={loading}
//           sx={{
//             '& .MuiDataGrid-columnHeaders': {
//               top: 0,
//             },
//           }}
//         />
//       </div>
//     </Box>
//   );
// }

// export default Ssldata;


const CustomToolbar = React.memo(({ rows, columns, onSearch, searchTerm, inputRef }) => {
  const handleExportExcel = () => {
    if (!rows.length) {
      alert('No data available to export');
      return;
    }
    const ws = XLSX.utils.json_to_sheet(
      rows.map((row) => {
        const newRow = {};
        columns.forEach((col) => {
          newRow[col.headerName] = row[col.field] || ''; // Handle null/undefined
        });
        return newRow;
      })
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'data.xlsx');
  };

  const handleExportCSV = () => {
    if (!rows.length) {
      alert('No data available to export');
      return;
    }
    const csvData = XLSX.utils.json_to_sheet(
      rows.map((row) => {
        const newRow = {};
        columns.forEach((col) => {
          newRow[col.headerName] = row[col.field] || ''; // Handle null/undefined
        });
        return newRow;
      })
    );
    const csv = XLSX.utils.sheet_to_csv(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'data.csv');
  };

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    onSearch(newSearchTerm);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <GridToolbarContainer
      sx={{
        bgcolor: 'white',
        justifyContent: 'center',
        padding: 1,
        display: 'flex',
        gap: 2,
      }}
    >
      <Button
        onClick={handleExportExcel}
        variant="contained"
        color="primary"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 1,
          fontSize: '16px',
          textTransform: 'none',
        }}
      >
        <Typography variant="body2" sx={{ color: 'white', marginRight: 1 }}>
          Export xlsx
        </Typography>
      </Button>

      <Button
        onClick={handleExportCSV}
        variant="contained"
        color="primary"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 1,
          fontSize: '16px',
          textTransform: 'none',
        }}
      >
        <Typography variant="body2" sx={{ color: 'white', marginRight: 1 }}>
          Export CSV
        </Typography>
      </Button>

      <input
        type="text"
        placeholder="Search Device Id"
        value={searchTerm}
        onChange={handleSearchChange}
        style={{ padding: '8px', fontSize: '16px' }}
        ref={inputRef}
      />
    </GridToolbarContainer>
  );
});

function Ssldata() {
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const ssldata = useSelector((state) => state.ssldata.data);
  const [searchTerm, setSearchTerm] = useState('');
  const searchInputRef = useRef(null);
  const [filterModel, setFilterModel] = useState({ items: [] });

  const columns = [
    { field: 'Deviceid', headerName: 'Device ID-IMEI', width: 150 },
    { field: 'Rms_mobile_no', headerName: 'RMS Mobile No', width: 150 },
    { field: 'Pole_sr_no', headerName: 'Pole Serial No', width: 150 },
    { field: 'Luminiare_sr_no', headerName: 'Luminiare Sr. No.', width: 150 },
    { field: 'Panel_sr_no', headerName: 'Panel Sr. No.', width: 150 },
    { field: 'Battery_sr_no', headerName: 'Battery Sr. No.', width: 150 },
    { field: 'Last_Update', headerName: 'Last Updated', width: 150 },
    {
      field: 'tabularData',
      headerName: 'Tabular Data',
      width: 150,
      renderCell: (params) => <TabularDataCell params={params} />,
      sortable: false,
      filterable: false,
    },
    {
      field: 'geolocation',
      headerName: 'GeoLocation Data',
      width: 150,
      renderCell: (params) => <Geolocationcell params={params} />,
      sortable: false,
      filterable: false,
    },
  ];

  useEffect(() => {
    setLoading(true);
    axios
      .get(`http://localhost:4000/api/v1/testpoint`, { withCredentials: true })
      .then((response) => {
        dispatch(fetchDataSuccess({ data: response.data.data }));
      })
      .catch((error) => {
        dispatch(fetchDataFailure(error.toString()));
      })
      .finally(() => setLoading(false));
  }, [dispatch]);

  const handlePageChange = (newPage) => {
    console.log("The new page is ",newPage)
    if (newPage <= 0 || newPage > Math.ceil(ssldata.length / pageSize)) return; // Out-of-bounds check
    setLoading(true);
    setTimeout(() => {
      setCurrentPage(newPage);
      setLoading(false);
    }, 500);
  };

  const handlePageSizeChange = (newPageSize) => {
    setLoading(true);
    setTimeout(() => {
      setPageSize(newPageSize);
      setCurrentPage(1);
      setLoading(false);
    }, 500);
  };

  const filteredRows = useMemo(() => {
    if (filterModel.items.length > 0) {
      return ssldata.filter((row) =>
        filterModel.items.every((filter) => {
          const value = row[filter.columnField] || ''; // Handle null/undefined
          return value.toLowerCase().includes(filter.value.toLowerCase());
        })
      );
    }
    return ssldata;
  }, [ssldata, filterModel]);

  const searchedRows = useMemo(() => {
    if (searchTerm) {
      return filteredRows.filter((row) =>
        (row.Deviceid || '').toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    return filteredRows;
  }, [filteredRows, searchTerm]);

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [loading]);

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">Solar Street Light Data</Typography>
        <Typography variant="h6">Dashboard - Solar Street Light Data</Typography>
      </Box>
      <div style={{ height: '80vh', width: '100%' }}>
      <DataGrid
  getRowId={(row) => row._id}
  rows={searchedRows}
  columns={columns}
  pageSize={pageSize}
  components={{
    Toolbar: () => (
      <CustomToolbar
        rows={searchedRows}
        columns={columns}
        onSearch={handleSearch}
        searchTerm={searchTerm}
        inputRef={searchInputRef}
      />
    ),
  }}
  rowsPerPageOptions={[10, 20, 25, 50]}
  pagination
  page={currentPage - 1}
  onPageChange={(newPage) => handlePageChange(newPage + 1)}
  onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
  loading={loading}
/>

      </div>
    </Box>
  );
}

export default Ssldata;
