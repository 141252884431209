import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import {  useNavigate } from "react-router-dom";
import srslogo from './SRS_LOGO.jpg'

const Topbar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  // const [logout,setlogout] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box display="flex" justifyContent="space-between" p={2} 
     sx={{
        position: 'sticky',
        top: 0,
        zIndex: 1000, // ensure it stays above other content
        backgroundColor: theme.palette.mode === 'dark'? colors.primary[500] : 'white', // background color to ensure it remains visible
      }} >
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>
      {/* Logo */}
      <Box  sx={{ 
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         height: '100%', // Ensures the logo takes up the full height of the parent container
         img: {
           maxWidth: '5%', // Ensures the image scales down when the container size is reduced
           maxHeight: '5%', // Maintains the aspect ratio while fitting within the container
           height: 'auto',    // Adjusts height to keep aspect ratio
           width: 'auto',     // Adjusts width to keep aspect ratio
         }
       }}  > <img src={srslogo} alt="SRI RAM SAGAR" />  </Box>
      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton onClick={handleClick} >
          <PersonOutlinedIcon  />
        </IconButton>
        {/* {  logout && <SelectSmall />}  */}
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2 }}>
        {/* <Box onClick={ ()=>{
      localStorage.clear();
      navigate("/");
    }} sx={{ display: 'flex', alignItems: 'center' }}>
    <LogoutIcon  sx={{ mr: 1 }} />
    Logout
  
  </Box> */}

<Box
      onClick={() => {
        localStorage.clear();   
  //       res.clearCookie('token', { httpOnly: true, secure: true, sameSite: 'None' });
  // res.status(200).json({ success: true, message: 'Logged out successfully' });
        navigate("/");
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer', // Add cursor pointer to indicate it's clickable
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)', // Change background color on hover
        },
        padding: '4px', // Add some padding for better UX
        borderRadius: '4px', // Add border-radius for rounded corners
      }}
    >
      <LogoutIcon sx={{ mr: 1 }} /> {/* Adds some margin to the right of the icon */}
      Logout
    </Box>

        </Typography>
      </Popover>
      </Box>
    </Box>
  );
};

export default Topbar;




//  function SelectSmall() {
//   const [age, setAge] = React.useState('');

//   const handleChange = (event) => {
//     setAge(event.target.value);
//   };

//   return (
//     <Box  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 2 }} >
//     <FormControl sx={{  minWidth: 120 }} size="small">
//       <InputLabel id="demo-select-small-label">Age</InputLabel>
//       <Select
//         labelId="demo-select-small-label"
//         id="demo-select-small"
//         value={age}
//         label="Age"
//         onChange={handleChange}
//       >
//         <MenuItem value="">
//           <em>None</em>
//         </MenuItem>
//         <MenuItem value={10}>Ten</MenuItem>
//         <MenuItem value={20}>Twenty</MenuItem>
//         <MenuItem value={30}>Thirty</MenuItem>
//       </Select>
//     </FormControl>
//     </Box>
//   );
// }


// import * as React from 'react';


// export default function BasicPopover() {
  

//   return (
//     <div>
//       <Button aria-describedby={id} variant="contained" onClick={handleClick}>
//         Open Popover
//       </Button>
     
//     </div>
//   );
// }