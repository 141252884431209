// import React from 'react'
// import { useState } from "react";
// import Topbar from "./scenes/global/Topbar";
// import Sidebar from "./scenes/global/Sidebar";
// import { CssBaseline, ThemeProvider } from "@mui/material";
// import { ColorModeContext, useMode } from "./theme";
// import { Outlet } from 'react-router-dom';
// import Footer from './components/Footer';
// import "./index.css";

// function Admindashboard() {
//     const [theme, colorMode] = useMode();
//     const [isSidebar, setIsSidebar] = useState(false);
//     localStorage.setItem('mode', theme.palette.mode );
//     return (
//         <div>
//             <ColorModeContext.Provider value={colorMode}>
//                 <ThemeProvider theme={theme}>
//                     <CssBaseline />
//                     <div className="app">
//                         <Sidebar isSidebar={isSidebar} />
//                         <main className="content"  >
//                             <Topbar setIsSidebar={setIsSidebar} />
//                             <Outlet/>
//                             <Footer />
//                         </main>
//                     </div>
//                 </ThemeProvider>
//             </ColorModeContext.Provider>
//         </div>
//     )
// }
// export default Admindashboard


import React, { useState } from 'react';
// import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
// import Calendar from "./scenes/calendar/calendar";
import { Outlet } from 'react-router-dom';
import Footer from './components/Footer';
import "./index.css";
import { useSelector } from 'react-redux';
import Spinner from './Spinner';

function Admindashboard() {
    
    const myData = useSelector((state) => state.auth.token);
    console.log("The data for the token is ", myData);
    const [theme, colorMode] = useMode();
    const [isSidebar, setIsSidebar] = useState(false);

    // Save the theme mode in local storage
    localStorage.setItem('mode', theme.palette.mode);

    return (
        myData ? (
            <div>
                <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <div className="app">
                        <Sidebar isSidebar={isSidebar} />
                        <main className="content">
                            <Topbar setIsSidebar={setIsSidebar} />
                            <Outlet />
                            <Footer />
                        </main>
                    </div>
                </ThemeProvider>
            </ColorModeContext.Provider>
            </div>
        ) : (
            <Spinner />
        )
    );
}

export default Admindashboard;



